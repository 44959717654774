import "./App.css";
import "./Routes/Login";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./Routes/Login";
import ResetPassword from "./Routes/ResetPassword";

function App() {
  return (
    <Routes>
      <Route path="/" element={<ResetPassword />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
