import axios from "axios";

// Create an Axios instance with default headers
// const accessToken = JSON.parse(localStorage.getItem("token"));
let headers = {
  "Content-Type": "application/json", // Adjust the content type as needed
};
// if (accessToken != null) {
//   headers["Authorization"] = `Bearer ${accessToken}`; // Add the token to the 'Authorization' header
// }
const axiosInstance = axios.create({
  baseURL: "https://api-wiweave.techdarshak.com/api", // Replace with your API base URL
  headers: headers,
});
export default axiosInstance;
// "http://localhost:8080"
