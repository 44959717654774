import React from "react";
import "./LoginStyles.css";
import { useState } from "react";
import axiosInstance from "../services/axios";
import { useNavigate } from 'react-router-dom';

function App() {
  const [formInput, setFormInput] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();


  const [formError, setFormError] = useState({
    email: "",
    password: "",
  });

  const handleUserInput = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };

  const validateFormInput = async (event) => {
    event.preventDefault();
    let inputError = {
      email: "",
      password: "",
    };

    if (!formInput.email && !formInput.password) {
      setFormError({
        ...inputError,
        email: "Enter valid email address",
        password: "Password should not be empty",
      });
      return;
    }
    setFormError(inputError);
    try {
      const response = await axiosInstance.post("/login", formInput);
      if (response.data["success"] == true) {
        let UserDetails = response.data.data;
        console.log(UserDetails,'Login Successfully ')
        // history.push('/reset-password');
        navigate("/reset-password");
        // localStorage.setItem("token", JSON.stringify(UserDetails.token));
        // localStorage.setItem(
        //   "userDetails",
        //   JSON.stringify(UserDetails.userData)
        // );
        // localStorage.setItem("loginData", JSON.stringify(UserDetails));
      }
      
    } catch (error) {
      console.log(error); // handle the error
      if(error.response.status == 422){
        setFormError({
          ...inputError,
          password: "Wrong password entered",
        });
      }
      // setError(error.response.data.message);
      // setLoader(false);
    }
  };

  return (
    <div className="App-container">
      <div className="card">
        <div className="card-body">
          <form onSubmit={validateFormInput}>
            <p className="label">Email</p>
            <input
              value={formInput.email}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
              name="email"
              type="email"
              className="input"
              placeholder="Email"
            />
            <p className="error-message">{formError.email}</p>
            <p className="label">Password</p>
            <input
              value={formInput.password}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
              name="password"
              type="password"
              className="input"
              placeholder="Password"
            />
            <p className="error-message">{formError.password}</p>

            {/* <p className="label">Confirm Password</p>
            <input
              value={formInput.confirmPassword}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
              name="confirmPassword"
              type="password"
              className="input"
              placeholder="Confirm Password"
            />
            <p className="error-message">{formError.confirmPassword}</p> */}

            <input type="submit" className="btn" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;
